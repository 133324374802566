import 'css-chunk:app/javascript/icon_gen/components/Card.module.scss';export default {
  "cardWrapper": "_cardWrapper_p136g_1",
  "loaded": "_loaded_p136g_11",
  "rounded": "_rounded_p136g_15",
  "error": "_error_p136g_18",
  "imageWrapper": "_imageWrapper_p136g_37",
  "image": "_image_p136g_37",
  "fadeIn": "_fadeIn_p136g_1",
  "styleName": "_styleName_p136g_107",
  "useStyleWrapper": "_useStyleWrapper_p136g_117",
  "voteButtonsWrapper": "_voteButtonsWrapper_p136g_135",
  "voteStateWrapper": "_voteStateWrapper_p136g_154",
  "upvotedIcon": "_upvotedIcon_p136g_161",
  "loadingWrapper": "_loadingWrapper_p136g_185",
  "errorWrapper": "_errorWrapper_p136g_200",
  "errorIcon": "_errorIcon_p136g_208",
  "errorButton": "_errorButton_p136g_214",
  "debug": "_debug_p136g_229",
  "variants": "_variants_p136g_234",
  "variant": "_variant_p136g_234",
  "variantCurrent": "_variantCurrent_p136g_257"
};