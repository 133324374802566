import styles from './History.module.scss'
import { useCallback, useEffect, useState } from 'react'
import ImageGenRequest from './ImageGenRequest'
import useImageGenStore from '../lib/imageGenStore'

export default function History() {
  const [historySince, setHistorySince] = useState(null)
  const [histories, setHistories] = useState([])
  const [hasMore, setHasMore] = useState(false)
  const [loadingMore, setLoadingMore] = useState(false)
  const addImageGens = useImageGenStore((state) => state.addImageGens)

  const fetchAllHistories = useCallback(async () => {
    setLoadingMore(true)
    const response = await fetch('/api/v1/image_gen/history', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        since: historySince,
      }),
    })

    const data = await response.json()
    setHasMore(false)
    setLoadingMore(false)
    if (data && data.length > 0) {
      setHistories((oldData) => [...oldData, ...data])
      setHistorySince(data[data.length - 1].createdAt)
      if (data.length > 0) {
        // not super accurate, the server should probably tell us if there are more. but it works well enough for now.
        setHasMore(true)
      }

      data.map((imageGenRequest) => {
        if (imageGenRequest.images.length > 0) {
          addImageGens(imageGenRequest.images)
        }
      })
    }
  }, [historySince])

  useEffect(() => {
    fetchAllHistories()
  }, [])

  return (
    <>
      {histories.map((imageGenRequest) => {
        if (imageGenRequest.images.length > 0) {
          return (
            <ImageGenRequest
              key={imageGenRequest.id}
              {...imageGenRequest}
              completeCallback={() => {}}
            />
          )
        }
      })}
      {hasMore && (
        <button
          className={styles.loadMoreButton}
          onClick={fetchAllHistories}
          disabled={loadingMore}
        >
          {loadingMore ? 'Loading' : 'Load More'}
        </button>
      )}
    </>
  )
}
