import { useCallback, useContext, useEffect, useState } from 'react'
import styles from './Favorites.module.scss'
import useImageGenStore from './lib/imageGenStore'
import GeneratePrompt from './components/GeneratePrompt'
import { pageViewEvent } from '../lib/events'
import Card from './components/Card'
import GridWrapper from './components/GridWrapper'

const Favorites = () => {
  const [favoritesSince, setFavoritesSince] = useState(null)
  const [favorites, setFavorites] = useState([])
  const addImageGens = useImageGenStore((state) => state.addImageGens)

  useEffect(() => {
    pageViewEvent({ pageType: 'imageGenFavorites' })
  }, [])

  const fetchAllFavorites = useCallback(async () => {
    const response = await fetch('/api/v1/image_gen/favorites', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        since: favoritesSince,
      }),
    })

    const data = await response.json()
    if (data && data.length > 0) {
      setFavorites((oldData) => [...oldData, ...data])
      setFavoritesSince(data[data.length - 1].id)
      addImageGens(data)
    }
  }, [favoritesSince])

  useEffect(() => {
    fetchAllFavorites()
  }, [])

  return (
    <main className={`${styles.wrapper} `}>
      <div className={styles.promptWrapper}>
        <GeneratePrompt />
      </div>

      <GridWrapper>
        {favorites.slice(0, 64).map((imageGenerator) => {
          return (
            <Card
              key={imageGenerator.id}
              id={imageGenerator.id}
              assetUrl={imageGenerator.asset_url}
              assetUrlThumb={imageGenerator.asset_url_thumb}
              status={imageGenerator.status}
              token={null}
              debug={imageGenerator.debug}
              caption={imageGenerator.caption}
              renderCallback={() => {}}
            />
          )
        })}
      </GridWrapper>
    </main>
  )
}

export default Favorites
