import React from 'react'
import Heading from './Heading'
import styles from './Home.module.scss'
import GeneratePrompt from './GeneratePrompt'
import { Tooltip } from 'react-tooltip'
import Card from './Card'
import GridWrapper from './GridWrapper'
import { useIconGenerator } from '@envato/labs-extensions-client'

const Home = () => {
  const {
    results,
    status,
    initialized,
    error,
    handleCancel,
    handleGenerate,
    styles: iconGenStyles,
  } = useIconGenerator({ endpoint: '/api/v1/icon_gen/icon_config' })

  return (
    <main className={`${styles.main} `}>
      {status !== 'ready' && !error ? (
        <div>
          <div>
            <button onClick={handleCancel}>Return</button>
            {status === 'pending' && 'Loading...'}
            {status === 'generating' && 'Generating...'}
            {status === 'error' && 'Error...'}
            {status === 'complete' && 'Generated Icons'}
          </div>

          <GridWrapper>
            {results.map((item) => {
              return (
                <Card
                  key={item.id}
                  id={item.id}
                  type={'icon'}
                  assetUrl={item.url}
                  iconVariants={item.variants}
                />
              )
            })}
          </GridWrapper>
        </div>
      ) : (
        <>
          <Heading
            heading='AI IconGen'
            subheading={
              <>Generate any icon with AI from over 30 trained styles.</>
            }
            showBeta={true}
          />
          <div className={styles.promptWrapper}>
            {error && <div>{error}</div>}
            <GeneratePrompt
              onGenerate={handleGenerate}
              loading={!initialized}
              generationStyles={iconGenStyles}
            />
          </div>
        </>
      )}

      <div className={styles.tooltipWrapper}>
        <Tooltip id='icon-gen-tooltips' />
      </div>
    </main>
  )
}

export default Home
