import { createRoot } from 'react-dom/client'
import Router from './Router'

const dom = document.getElementById('js--icon-gen-app')
if (dom) {
  const adminView = dom.dataset.adminView === 'true'
  const config = JSON.parse(dom.dataset.config)

  const root = createRoot(dom)
  root.render(<Router adminView={adminView} config={config} />)
}
