import { create } from 'zustand'

type ImageGeneratorRequestData = {
  id: string
  subject: string
  useCase: string
  orientation: string
  requestType: string
  imageCount: number
}

interface ImageGenStoreState {
  config: {
    imageGenApiHostname?: string
    imageGenApiPath?: string
  }
  tooltips: {
    showVoting: boolean
    showReport: boolean
    showShare: boolean
    showUseStyle: boolean
    showEditImage: boolean
  }
  currentSearchConfig: Record<string, any>
  imageGens: Record<string, any>
  imageGenRequests: ImageGeneratorRequestData[]
  imageGensPendingOperations: string[]
  pendingOperationComplete: (completedId: string) => void
  addImageGenRequest: (data: ImageGeneratorRequestData) => void
  addImageGens: (data: Record<string, any>[]) => void
  upvote: (id: string) => void
  downvote: (id: string) => void
  report: (id: string) => void
  share: () => void
}

const useImageGenStore = create<ImageGenStoreState>((set) => ({
  config: {},
  tooltips: {
    showVoting: true,
    showReport: true,
    showShare: true,
    showUseStyle: true,
    showEditImage: true,
  },
  currentSearchConfig: {},
  imageGens: {},
  imageGenRequests: <ImageGeneratorRequestData[]>[],
  imageGensPendingOperations: [],
  pendingOperationComplete: (completedId) =>
    set((state) => ({
      imageGensPendingOperations: state.imageGensPendingOperations.filter(
        (o) => o !== completedId,
      ),
    })),
  addImageGenRequest: (data) =>
    set((state) => {
      if (state.imageGensPendingOperations.length >= 2) {
        throw new Error('Please wait')
      }
      return {
        imageGensPendingOperations: [
          ...state.imageGensPendingOperations,
          data.id,
        ],
        imageGenRequests: [data, ...state.imageGenRequests],
      }
    }),
  addImageGens: (data) =>
    set((state) => ({
      imageGens: {
        ...state.imageGens,
        ...data.reduce(
          (acc, imageGen) => ({
            ...acc,
            [imageGen.id]: {
              ...imageGen,
            },
          }),
          {},
        ),
      },
    })),
  upvote: (id) =>
    set((state) => ({
      tooltips: {
        ...state.tooltips,
        showVoting: false,
      },
      imageGens: {
        ...state.imageGens,
        [id]: {
          ...state.imageGens[id],
          vote: 'upvote',
        },
      },
    })),
  downvote: (id) =>
    set((state) => ({
      tooltips: {
        ...state.tooltips,
        showVoting: false,
      },
      imageGens: {
        ...state.imageGens,
        [id]: {
          ...state.imageGens[id],
          vote: 'downvote',
        },
      },
    })),
  report: (id) =>
    set((state) => ({
      tooltips: {
        ...state.tooltips,
        showReport: false,
      },
      imageGens: {
        ...state.imageGens,
        [id]: {
          ...state.imageGens[id],
          reported: !state.imageGens[id].reported,
        },
      },
    })),
  share: () =>
    set((state) => ({
      tooltips: {
        ...state.tooltips,
        showShare: false,
      },
    })),
}))

export default useImageGenStore
