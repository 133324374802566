import styles from './GridWrapper.module.scss'

export default function GridWrapper({ title = null, children }) {
  return (
    <div className={styles.wrapper}>
      {title ? <div className={styles.title}>{title}</div> : null}
      <div className={styles.grid}>{children}</div>
    </div>
  )
}
