import styles from './MoreButtons.module.scss'
import { useContext } from 'react'
import useImageGenStore from '../../lib/imageGenStore'
import { ModalContext } from '../../context/ModalContext'
import logInteraction from '../../lib/logInteraction'
import DownloadButton from './DownloadButton'
import ReportButton from './ReportButton'
import EditButtonBria from './EditButtonBria'
import { ImageGenContext } from '../../context/ImageGenContext'

export default function MoreButtons({ id }) {
  const imageGens = useImageGenStore((state) => state.imageGens)

  return (
    <div className={styles.dots}>
      <div className={styles.moreWrapper}>
        <div className={styles.more}>
          <div>
            <EditButtonBria id={id} mode={'popup'} />
          </div>
          <div>
            <DownloadButton id={id} mode={'popup'} />
          </div>
          <div>
            <ReportButton id={id} mode={'popup'} />
          </div>
        </div>
      </div>
      <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M6.23066 13.5C5.81818 13.5 5.46506 13.3531 5.17131 13.0593C4.87758 12.7656 4.73071 12.4125 4.73071 12C4.73071 11.5875 4.87758 11.2344 5.17131 10.9406C5.46506 10.6469 5.81818 10.5 6.23066 10.5C6.64316 10.5 6.99628 10.6469 7.29001 10.9406C7.58376 11.2344 7.73064 11.5875 7.73064 12C7.73064 12.4125 7.58376 12.7656 7.29001 13.0593C6.99628 13.3531 6.64316 13.5 6.23066 13.5ZM11.9999 13.5C11.5874 13.5 11.2343 13.3531 10.9405 13.0593C10.6468 12.7656 10.4999 12.4125 10.4999 12C10.4999 11.5875 10.6468 11.2344 10.9405 10.9406C11.2343 10.6469 11.5874 10.5 11.9999 10.5C12.4124 10.5 12.7655 10.6469 13.0592 10.9406C13.353 11.2344 13.4999 11.5875 13.4999 12C13.4999 12.4125 13.353 12.7656 13.0592 13.0593C12.7655 13.3531 12.4124 13.5 11.9999 13.5ZM17.7691 13.5C17.3566 13.5 17.0035 13.3531 16.7098 13.0593C16.416 12.7656 16.2691 12.4125 16.2691 12C16.2691 11.5875 16.416 11.2344 16.7098 10.9406C17.0035 10.6469 17.3566 10.5 17.7691 10.5C18.1816 10.5 18.5347 10.6469 18.8285 10.9406C19.1222 11.2344 19.2691 11.5875 19.2691 12C19.2691 12.4125 19.1222 12.7656 18.8285 13.0593C18.5347 13.3531 18.1816 13.5 17.7691 13.5Z'
          fill='#191919'
        />
      </svg>
    </div>
  )
}
