import { useEffect, useState } from 'react'
import GridWrapper from './GridWrapper'
import Card from './Card'
import CardPlaceholder from './CardPlaceholder'
import styles from './ImageGenRequest.module.scss'
import useImageGenStore from '../lib/imageGenStore'
import { useLocation, useNavigate } from 'react-router-dom'
import { genericEvent } from '../../lib/events'

type ImageGenRequestProps = {
  subject: string
  useCase: string
  orientation: string
  completeCallback: () => {}
  imageCount: number
  requestType: string
  imageGenGenerationId: string
}

type GenerateResponseData = {
  status: string
  generations: ImageGeneratorData[]
}

type ImageGeneratorData = {
  id: string
  asset_url: string
  asset_url_thumb: string
  token: string
  caption: string
  debug: string
}

export default function ImageGenRequest({
  id,
  status,
  subject,
  useCase,
  orientation,
  imageCount,
  images,
  completeCallback,
  requestType,
  headerThumbnailLink,
  headerThumbnail,
}) {
  const [imageGenerators, setImageGenerators] =
    useState<ImageGeneratorData[]>(images)
  const [pendingOperations, setPendingOperations] = useState(['init'])
  const [error, setError] = useState(null)
  const addImageGens = useImageGenStore((state) => state.addImageGens)
  const imageGens = useImageGenStore((state) => state.imageGens)
  const navigate = useNavigate()
  const location = useLocation()

  const gridTitle = (
    <div className={styles.gridTitle}>
      <div>{subject}</div>
      {requestType === 'match_style' ? (
        <div className={styles.gridTitleStyle}>
          Style
          <img
            src={headerThumbnail}
            className={styles.moreLikeImageThumb}
            onClick={() => {
              navigate(headerThumbnailLink)
            }}
          />
        </div>
      ) : null}
    </div>
  )

  useEffect(() => {
    if (images.length > 0) {
      setPendingOperations(images)
    }
  }, [images])

  useEffect(() => {
    const generateSomeImages = async () => {
      if (status !== 'creating') {
        return
      }

      try {
        //throw new Error('meow')

        const generateResponse = await fetch('/api/v1/image_gen/generate', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            id: id,
          }),
        })

        const generateJson =
          (await generateResponse.json()) as GenerateResponseData

        if (generateJson.status !== 'success') {
          throw new Error('Failed to generate images')
        }

        addImageGens(generateJson.generations)
        setImageGenerators(generateJson.generations)

        setPendingOperations((old) => [
          ...old.filter((o) => o !== `init`),
          ...generateJson.generations.map((g) => `gen-${g.id}`),
        ])
      } catch (e) {
        setError(
          'Sorry an error occured with this request, please try again soon.',
        )
        setPendingOperations([])
      }
    }
    generateSomeImages()
  }, [status])

  useEffect(() => {
    if (pendingOperations.length === 0) {
      completeCallback() // let our parent know we've finished completing all the images.
    }
  }, [pendingOperations])

  if (error) {
    return (
      <GridWrapper title={gridTitle}>
        <div className={styles.errorWrapper}>
          <div className={styles.errorMessage}>{error}</div>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 25 25'
            className={styles.errorIcon}
          >
            <g fill='#232326'>
              <path d='M12.5 13a4.5 4.5 0 1 0 4.5 4.5 4.505 4.505 0 0 0-4.5-4.5zm2.5 6.293-.707.707-1.793-1.793L10.707 20 10 19.293l1.793-1.793L10 15.707l.707-.707 1.793 1.793L14.293 15l.707.707-1.793 1.793z' />
              <path d='M19.99 7.21a4.49 4.49 0 0 0-8.82-.87A4.158 4.158 0 0 0 9.5 6a4.486 4.486 0 0 0-4.23 3.01A4.498 4.498 0 0 0 5.5 18h1.53a4.07 4.07 0 0 1-.03-.5 5.5 5.5 0 0 1 11 0 4.07 4.07 0 0 1-.03.5h.53a5.497 5.497 0 0 0 1.49-10.79z' />
            </g>
          </svg>
        </div>
      </GridWrapper>
    )
  }

  if (!imageGenerators.length) {
    return (
      <GridWrapper title={gridTitle}>
        {[...Array(imageCount)].map((_, index) => {
          return <CardPlaceholder key={index} />
        })}
      </GridWrapper>
    )
  }

  return (
    <GridWrapper title={gridTitle}>
      {imageGenerators.slice(0, 64).map((imageGenerator) => {
        return (
          <Card
            key={imageGenerator.id}
            id={imageGenerator.id}
            assetUrl={imageGenerator.asset_url}
            assetUrlThumb={imageGenerator.asset_url_thumb}
            status={imageGenerator.status}
            token={imageGenerator.token}
            debug={imageGenerator.debug}
            caption={imageGenerator.caption}
            renderCallback={() => {
              setPendingOperations((old) =>
                [...old].filter((o) => o !== `gen-${imageGenerator.id}`),
              )
            }}
          />
        )
      })}
    </GridWrapper>
  )
}
