import styles from './GeneratePrompt.module.scss'
import { useCallback, useContext, useEffect, useState } from 'react'
import { ImageGenContext } from '../context/ImageGenContext'
import { useLocation, useNavigate } from 'react-router-dom'
import useImageGenStore from '../lib/imageGenStore'
import { genericEvent } from '../../lib/events'
import { usePromptRewrite } from '../lib/usePromptRewrite'

type CreateResponseData = {
  id: string
  status: string
  error: string
}

export default function GeneratePrompt({ showSuggestions = false }) {
  const [focus, setFocus] = useState(false)
  const [alertMessage, setAlertMessage] = useState(null)
  const { isAdmin, authorized } = useContext(ImageGenContext)
  const [loading, setLoading] = useState(false)
  const [highlightNotice, setHighlightNotice] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const imageGens = useImageGenStore((state) => state.imageGens)
  const imageGensPendingOperations = useImageGenStore(
    (state) => state.imageGensPendingOperations,
  )
  const addImageGenRequest = useImageGenStore(
    (state) => state.addImageGenRequest,
  )
  const currentSearchConfig = useImageGenStore(
    (state) => state.currentSearchConfig,
  )
  const currentSearchConfigRequestType = useImageGenStore(
    (state) => state.currentSearchConfig?.requestType,
  )
  const [subjectInput, setSubjectInput] = useState(
    currentSearchConfig?.subject || '',
  )

  let rewriteLoading = false
  let rewritePrompt = null
  if (isAdmin) {
    // todo: after removing isAdmin, change this to just:
    // const [rewriteLoading, rewritePrompt] = usePromptRewrite(subjectInput)
    const rewrite = usePromptRewrite(subjectInput)
    rewriteLoading = rewrite[0]
    rewritePrompt = rewrite[1]
  }

  useEffect(() => {
    if (currentSearchConfig?.subject) {
      setSubjectInput(currentSearchConfig.subject)
    }
  }, [currentSearchConfig])

  useEffect(() => {
    if (currentSearchConfigRequestType === 'match_style') {
      setHighlightNotice(true)
      setTimeout(() => {
        setHighlightNotice(false)
      }, 1100)
      // auto focus that input box too
      document.querySelector('[data-prompt-focus="true"]').focus()
    }
  }, [currentSearchConfigRequestType])

  const allowedConcurrentGenerations = 2
  const isAllowedToGenerateMore =
    !loading && imageGensPendingOperations.length < allowedConcurrentGenerations

  const generateImages = useCallback(async () => {
    if (loading) {
      return false
    }
    setAlertMessage(null)

    if (!authorized) {
      setAlertMessage('You gotta login first')
      return
    }

    if (
      subjectInput === 'spider' &&
      !window.spiderDone &&
      typeof SpiderController !== 'undefined'
    ) {
      window.spiderDone = true
      new SpiderController()
    }
    const imageGenCreateProperties = {
      requestType: 'simple',
      ...currentSearchConfig,
      subject: subjectInput,
    }

    if (!imageGenCreateProperties.subject.length) {
      setAlertMessage('Please enter a subject first')
      return
    }

    useImageGenStore.setState({
      currentSearchConfig: imageGenCreateProperties,
    })

    setLoading(true)

    try {
      genericEvent({
        event: 'imageGenCreate',
        data: imageGenCreateProperties,
      })

      const createResponse = await fetch('/api/v1/image_gen/create', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(imageGenCreateProperties),
      })

      const createJson = (await createResponse.json()) as CreateResponseData

      if (createJson.status === 'usage_limit_daily') {
        throw new Error(
          'You’ve hit today’s ImageGen limit! 🚀 You’re a top 1% creator. Make more magic tomorrow.',
        )
      }
      if (createJson.status === 'usage_limit_concurrent') {
        throw new Error(
          'Please wait a little while between requests to give the AI time to recover 🤖',
        )
      }
      if (createJson.status === 'blocked') {
        throw new Error('Please try different words')
      }
      if (createJson.status !== 'creating') {
        throw new Error('Failed to generate images')
      }

      addImageGenRequest(createJson)

      if (location.pathname !== '/image-gen/generate') {
        navigate('/image-gen/generate')
      }
    } catch (e) {
      genericEvent({
        event: 'imageGenFailed',
        data: imageGenCreateProperties,
      })

      setAlertMessage(e.message)
    }
    setLoading(false)
  }, [loading, currentSearchConfig, subjectInput])

  return (
    <div
      className={`${styles.wrapper} ${focus ? styles.hasFocus : ''} ${
        highlightNotice ? styles.highlightNotice : ''
      }`}
    >
      <form
        action='/image-gen/generate'
        className={styles.border}
        onSubmit={(e) => {
          e.preventDefault()
          generateImages()
          return false
        }}
      >
        {currentSearchConfig &&
        currentSearchConfig.requestType === 'match_style' ? (
          <div className={styles.promptStyle}>
            <img
              src={
                imageGens[currentSearchConfig.imageGenGenerationId]
                  .asset_url_thumb
              }
              className={styles.promptStyleThumb}
              onClick={() => {
                // navigate(
                //     `/image-gen/${currentSearchConfig.imageGenGenerationId}`,
                //     {
                //       state: { from: location },
                //     },
                // )
              }}
            />
            <button
              onClick={() => {
                useImageGenStore.setState({
                  currentSearchConfig: {},
                })
              }}
              className={styles.promptStyleClear}
            >
              <svg
                width='17'
                height='16'
                viewBox='0 0 17 16'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <rect x='0.5' width='16' height='16' rx='8' fill='#191919' />
                <path
                  d='M4.76664 12.4357L4.06409 11.7332L7.79742 7.99985L4.06409 4.26652L4.76664 3.56396L8.49997 7.2973L12.2333 3.56396L12.9359 4.26652L9.20252 7.99985L12.9359 11.7332L12.2333 12.4357L8.49997 8.7024L4.76664 12.4357Z'
                  fill='white'
                />
              </svg>
            </button>
          </div>
        ) : null}
        <input
          type='search'
          className={styles.promptInput}
          value={subjectInput}
          data-prompt-focus='true'
          placeholder='Enter a subject (e.g. bonsai, cat)'
          onFocus={() => {
            setFocus(true)
          }}
          onBlur={() => {
            setFocus(false)
          }}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              event.preventDefault()
              generateImages()
              return false
            }
          }}
          onChange={(e) => {
            setSubjectInput(e.target.value)
          }}
        />
        <button
          type='submit'
          className={`${styles.promptButton} ${
            !isAllowedToGenerateMore ? styles.promptButtonLoading : ''
          }`}
          onClick={(e) => {
            e.preventDefault()
            generateImages()
            return false
          }}
        >
          <span className={styles.promptLabel}>
            {!isAllowedToGenerateMore ? 'Please wait' : 'Generate'}
          </span>
          <svg
            width='18'
            height='16'
            viewBox='0 0 18 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M8.74608 5.02464C9.08718 4.94904 9.35358 4.68264 9.42918 4.34154L10.2266 0.754138C10.4354 -0.185462 11.7746 -0.185462 11.9834 0.754138L12.7808 4.34154C12.8564 4.68264 13.1228 4.94904 13.4639 5.02464L17.0513 5.82204C17.9909 6.03084 17.9909 7.37004 17.0513 7.57884L13.4639 8.37624C13.1228 8.45184 12.8564 8.71824 12.7808 9.05934L11.9834 12.6467C11.7746 13.5863 10.4354 13.5863 10.2266 12.6467L9.42918 9.05934C9.35358 8.71824 9.08718 8.45184 8.74608 8.37624L5.15868 7.57884C4.21908 7.37004 4.21908 6.03084 5.15868 5.82204L8.74608 5.02464Z'
              fill='#E5E5E5'
            />
            <path
              d='M1.63803 12.6498C1.97913 12.5742 2.24553 12.3078 2.32113 11.9667L2.56503 10.8687C2.65233 10.4745 3.21393 10.4745 3.30123 10.8687L3.54513 11.9667C3.62073 12.3078 3.88713 12.5742 4.22823 12.6498L5.32623 12.8937C5.72043 12.981 5.72043 13.5426 5.32623 13.6299L4.22823 13.8738C3.88713 13.9494 3.62073 14.2158 3.54513 14.5569L3.30123 15.6549C3.21393 16.0491 2.65233 16.0491 2.56503 15.6549L2.32113 14.5569C2.24553 14.2158 1.97913 13.9494 1.63803 13.8738L0.540035 13.6299C0.145835 13.5426 0.145835 12.981 0.540035 12.8937L1.63803 12.6498Z'
              fill='#E5E5E5'
            />
          </svg>
        </button>
        {alertMessage ? (
          <div className={styles.promptAlert}>{alertMessage}</div>
        ) : null}
      </form>
      <div>
        {isAdmin && (
          <div>
            {(rewriteLoading || rewritePrompt) && (
              <div>
                <div>
                  {rewriteLoading
                    ? 'Loading suggestion'
                    : 'Try this suggested prompt'}
                </div>
                <div>
                  {rewritePrompt && (
                    <span
                      onClick={() => {
                        setSubjectInput(rewritePrompt)
                      }}
                    >
                      {rewritePrompt}
                    </span>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      {showSuggestions && (
        <div className={styles.smartSuggestionWrapper}>
          <svg
            className={styles.smartSuggestionIcon}
            width='21'
            height='20'
            viewBox='0 0 21 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M10.5001 17.9808C10.0791 17.9808 9.71615 17.8349 9.41112 17.5433C9.10611 17.2516 8.94024 16.8953 8.91352 16.4744H12.0866C12.0599 16.8953 11.894 17.2516 11.589 17.5433C11.284 17.8349 10.921 17.9808 10.5001 17.9808ZM7.37508 15.3205V14.0705H13.625V15.3205H7.37508ZM7.50327 12.9167C6.63042 12.3739 5.93946 11.6648 5.43037 10.7893C4.92129 9.91374 4.66675 8.95622 4.66675 7.91669C4.66675 6.29277 5.23298 4.91457 6.36546 3.78208C7.49794 2.64961 8.87614 2.08337 10.5001 2.08337C12.124 2.08337 13.5022 2.64961 14.6347 3.78208C15.7671 4.91457 16.3334 6.29277 16.3334 7.91669C16.3334 8.95622 16.0788 9.91374 15.5697 10.7893C15.0607 11.6648 14.3697 12.3739 13.4969 12.9167H7.50327ZM7.87506 11.6667H13.1251C13.7501 11.2222 14.2327 10.6736 14.573 10.0209C14.9133 9.36808 15.0834 8.66669 15.0834 7.91669C15.0834 6.63891 14.6389 5.55558 13.7501 4.66669C12.8612 3.7778 11.7778 3.33335 10.5001 3.33335C9.22228 3.33335 8.13895 3.7778 7.25006 4.66669C6.36117 5.55558 5.91673 6.63891 5.91673 7.91669C5.91673 8.66669 6.08687 9.36808 6.42714 10.0209C6.76742 10.6736 7.25006 11.2222 7.87506 11.6667Z'
              fill='black'
            />
          </svg>
          <span className={styles.smartSuggestionTitle}>Smart Suggestion</span>
          <span className={styles.smartSuggestionContent}>
            Focus on detailed subject descriptions, rather than style. Learn
            more{' '}
            <a
              href='https://elements.envato.com/learn/envato-ai-image-generator-faqs'
              target='_blank'
              rel='noopener nofollow noreferrer'
            >
              Prompt Tips
            </a>
            .
          </span>
        </div>
      )}
    </div>
  )
}
