import styles from './Card.module.scss'
import { useCallback, useContext, useEffect, useState } from 'react'
import LoadingAnimation from './LoadingAnimation'
import { ImageGenContext } from '../context/ImageGenContext'
import { Link, useNavigate } from 'react-router-dom'
import logInteraction from '../lib/logInteraction'
import useImageGenStore from '../lib/imageGenStore'
import UseStyleButton from './buttons/UseStyleButton'
import VoteButtons from './buttons/VoteButtons'
import MoreButtons from './buttons/MoreButtons'

type ResponseData =
  | {
      message: string
    }
  | {
      status: string
    }

type CardProps = {
  id: string
  assetUrl: string
  assetUrlThumb: string
  status: string
  token: string
  caption: string
  debug: any
  renderCallback: () => {}
  allowPopup: boolean
}

export default function Card({
  id,
  assetUrl,
  assetUrlThumb,
  status,
  token,
  debug,
  caption,
  renderCallback,
  allowPopup = true,
}: CardProps) {
  const [retryTimestamp, setRetryTimestamp] = useState(Date.now())
  const [hasImage, setHasImage] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState<null | string>(
    status === 'error' ? 'Failed to generate image' : null,
  )
  const { isAdmin, setModalContent, setModalReturnUrl } =
    useContext(ImageGenContext)
  const navigate = useNavigate()
  const imageGens = useImageGenStore((state) => state.imageGens)
  const config = useImageGenStore((state) => state.config)
  const imageGen = imageGens[id]
  const vote = imageGen?.vote || 0

  const fetchData = useCallback(async () => {
    if (!token) {
      setHasImage(true)
      return
    }

    try {
      setHasImage(false)
      setError(null)

      const response = await fetch(
        `${config.imageGenApiHostname}${config.imageGenApiPath}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            jwt: token,
          }),
        },
      )

      const data = (await response.json()) as ResponseData

      if (!data.status) {
        setError(data.message)
        logInteraction({
          id: id,
          type: 'error',
          additionalData: {
            error: data.message,
            ...imageGen.analytics,
          },
        })
      } else {
        logInteraction({
          id: id,
          type: 'image-generated',
          additionalData: imageGen.analytics,
        })
        setHasImage(true)
      }
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message)
        logInteraction({
          id: id,
          type: 'error',
          additionalData: {
            error: error.message,
            ...imageGen.analytics,
          },
        })
      } else {
        setError('Unknown error')
        logInteraction({
          id: id,
          type: 'error',
          additionalData: {
            error: 'unknown',
            ...imageGen.analytics,
          },
        })
      }
    }

    renderCallback()
  }, [token])

  useEffect(() => {
    fetchData()
  }, [retryTimestamp])

  if (error) {
    return null
  }

  return (
    <div
      className={`${styles.cardWrapper} ${styles.rounded} ${
        !isLoading ? styles.loaded : ''
      } ${error ? styles.error : ''}`}
      data-vote={vote}
      data-reported={imageGen?.reported}
    >
      {!error && hasImage ? (
        <>
          <div className={styles.voteButtonsWrapper}>
            <MoreButtons id={id} />
            <VoteButtons id={id} />
          </div>
          <div className={styles.voteStateWrapper}>
            {vote === 'upvote' && (
              <div className={styles.upvotedIcon}>
                <svg
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M18 21H8V8L15 1L16.25 2.25C16.3667 2.36667 16.4625 2.525 16.5375 2.725C16.6125 2.925 16.65 3.11667 16.65 3.3V3.65L15.55 8H21C21.5333 8 22 8.2 22.4 8.6C22.8 9 23 9.46667 23 10V12C23 12.1167 22.9875 12.2417 22.9625 12.375C22.9375 12.5083 22.9 12.6333 22.85 12.75L19.85 19.8C19.7 20.1333 19.45 20.4167 19.1 20.65C18.75 20.8833 18.3833 21 18 21ZM6 8V21H2V8H6Z'
                    fill='#191919'
                  />
                </svg>
              </div>
            )}
          </div>
          <div className={styles.useStyleWrapper}>
            <UseStyleButton id={id} />
          </div>
          <Link
            to={allowPopup ? `/image-gen/${id}` : null}
            className={styles.imageWrapper}
          >
            <img
              src={assetUrlThumb}
              className={styles.image}
              alt={caption}
              loading='lazy'
              onLoad={() => {
                setIsLoading(false)
                // logInteraction({
                //   id: id,
                //   type: 'view',
                //   additionalData: imageGen.analytics,
                // })
              }}
              onError={() => {
                setIsLoading(false)
                setError('Loading src error')
                logInteraction({
                  id: id,
                  type: 'src-error',
                  additionalData: imageGen.analytics,
                })
              }}
            />
          </Link>
        </>
      ) : null}

      {!error ? (
        <div className={styles.loadingWrapper}>
          <LoadingAnimation />
        </div>
      ) : null}

      {error ? (
        <>
          <div className={styles.errorWrapper}>
            <svg
              className={styles.errorIcon}
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 25 25'
            >
              <g fill='#232326'>
                <path d='M12.5 13a4.5 4.5 0 1 0 4.5 4.5 4.505 4.505 0 0 0-4.5-4.5zm2.5 6.293-.707.707-1.793-1.793L10.707 20 10 19.293l1.793-1.793L10 15.707l.707-.707 1.793 1.793L14.293 15l.707.707-1.793 1.793z' />
                <path d='M19.99 7.21a4.49 4.49 0 0 0-8.82-.87A4.158 4.158 0 0 0 9.5 6a4.486 4.486 0 0 0-4.23 3.01A4.498 4.498 0 0 0 5.5 18h1.53a4.07 4.07 0 0 1-.03-.5 5.5 5.5 0 0 1 11 0 4.07 4.07 0 0 1-.03.5h.53a5.497 5.497 0 0 0 1.49-10.79z' />
              </g>
            </svg>
            {/*<button
              className={styles.errorButton}
              onClick={(e) => setRetryTimestamp(Date.now())}
            >
              Retry
            </button>*/}
          </div>
          {isAdmin ? (
            <div className={styles.details}>
              <>
                Error: {error} <br />
                Caption: {caption}
              </>
            </div>
          ) : null}
        </>
      ) : null}
    </div>
  )
}
