import Heading from './components/Heading'
import ImageGenPublished from './components/ImageGenPublished'
import styles from './Home.module.scss'
import GeneratePrompt from './components/GeneratePrompt'
import { useEffect } from 'react'
import { pageViewEvent } from '../lib/events'

const Home = () => {
  useEffect(() => {
    pageViewEvent({ pageType: 'imageGenHome' })
  }, [])
  return (
    <main className={`${styles.main} `}>
      <Heading
        heading='AI ImageGen'
        subheading={
          <>
            Inspirational images from the top AI generators.{' '}
            <br className={styles.responsiveBr} />
            Simply enter a subject, we’ll apply styles, and you’ll tweak the one
            you like.
          </>
        }
        showBeta={true}
      />

      <div className={styles.promptWrapper}>
        <GeneratePrompt showSuggestions={true} />
      </div>

      <ImageGenPublished />
    </main>
  )
}

export default Home
