import styles from './DebugButton.module.scss'
import { useCallback, useContext } from 'react'
import useImageGenStore from '../../lib/imageGenStore'
import { ModalContext } from '../../context/ModalContext'
import logInteraction from '../../lib/logInteraction'
import { ImageGenContext } from '../../context/ImageGenContext'

export default function DebugButton({ id }) {
  const { setDebugModalContent } = useContext(ImageGenContext)
  const imageGens = useImageGenStore((state) => state.imageGens)

  const showAdminDebugModal = useCallback(() => {
    setDebugModalContent(
      <div className={styles.debugWrapper}>
        <div>
          Feature this image?
          <button
            onClick={() => logInteraction({ id: id, type: 'feature' })}
            className={styles.debugPublish}
          >
            Publish
          </button>
        </div>
        <div>
          Debug: <br />
          <pre className={styles.debugPre}>
            {JSON.stringify(imageGens[id], null, 2)}
          </pre>
        </div>
      </div>,
    )
  }, [imageGens])

  return (
    <button
      onClick={() => {
        showAdminDebugModal()
      }}
      className={styles.debugButton}
    >
      Debug
    </button>
  )
}
