import styles from './ReportButton.module.scss'
import useImageGenStore from '../../lib/imageGenStore'
import logInteraction from '../../lib/logInteraction'

export default function ReportButton({ id, mode = null }) {
  const imageGens = useImageGenStore((state) => state.imageGens)
  const imageGenReport = useImageGenStore((state) => state.report)
  const tooltips = useImageGenStore((state) => state.tooltips)
  const imageGen = imageGens[id]
  const reported = imageGen?.reported

  return (
    <button
      onClick={() => {
        imageGenReport(id)
        logInteraction({
          id: id,
          type: reported ? 'unreport' : 'report',
          additionalData: imageGen.analytics,
        })
      }}
      className={`${styles.button} ${mode === 'popup' ? styles.popup : ''}`}
      data-tooltip-id={'image-gen-tooltips'}
      data-tooltip-place={'top'}
      data-tooltip-content={'Report inappropriate image'}
      data-tooltip-hidden={!tooltips.showReport}
    >
      {reported ? (
        <svg
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M5.5 20.75V4.5H13.5961L13.9961 6.5H19.5V15.5H13.4039L13.0039 13.5H6.99997V20.75H5.5Z'
            fill='black'
          />
        </svg>
      ) : (
        <svg
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M5.5 20.75V4.5H13.5961L13.9961 6.5H19.5V15.5H13.4039L13.0039 13.5H6.99997V20.75H5.5ZM14.65 14H18V7.99998H12.75L12.35 5.99998H6.99997V12H14.25L14.65 14Z'
            fill='#191919'
          />
        </svg>
      )}
      {mode === 'popup' && 'Report this'}
    </button>
  )
}
