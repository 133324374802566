import styles from './Curation.module.scss'
import { useCallback, useContext, useState } from 'react'
import { useParams } from 'react-router'
import { IconGenContext } from '../context/IconGenContext'
import { useIconGenerator } from '@envato/labs-extensions-client'
import Card from './Card'

type CurationSelectedEndpoints = { [k: string]: string }
const Curation = () => {
  const { config: adminConfig } = useContext(IconGenContext)
  const { id } = useParams()
  const [selectedEndpoints, setSelectedEndpoints] =
    useState<CurationSelectedEndpoints>({})
  const [promptText, setPromptText] = useState<string>('')

  const { results, status, error, handleGenerate } = useIconGenerator({
    endpoint: '/api/v1/icon_gen/icon_config',
  })

  const generateIcons = useCallback(() => {
    // We need to request icon generations for all the selected endpoints.
    Object.keys(selectedEndpoints).forEach((iconModelId) => {
      const endpointId = selectedEndpoints[iconModelId]
      if (!endpointId) {
        return
      }
      handleGenerate({
        prompt: promptText,
        id: id,
        icon_model_endpoint_id: endpointId,
        type: 'icon_pack_curation',
      })
    })
  }, [id, promptText, handleGenerate, selectedEndpoints])

  if (!id || !adminConfig) {
    return <div>Failed to load</div>
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.prompt}>
        <input
          type={'text'}
          value={promptText}
          onChange={(e) => setPromptText(e.target.value)}
        />
        <input
          type={'button'}
          value={'Generate Icons'}
          onClick={generateIcons}
        />
        {status}
        {error}
      </div>
      <table className='index_table index'>
        <thead>
          <tr>
            <th className={styles.iconModelColumn}>Icon Model</th>
            <th>Generated Images</th>
          </tr>
        </thead>
        <tbody>
          {adminConfig.admin_icon_models.map((iconModel) => {
            return (
              <tr key={iconModel.id}>
                <td>
                  <div>
                    <strong>{iconModel.name}</strong>
                  </div>
                  <div>Image: {iconModel.image_variant}</div>
                  <div>Caption: {iconModel.caption_variant}</div>
                  <div>
                    Endpoint:
                    <select
                      value={selectedEndpoints[iconModel.id] || ''}
                      onChange={(e) => {
                        setSelectedEndpoints({
                          ...selectedEndpoints,
                          [iconModel.id]: e.target.value,
                        })
                      }}
                    >
                      <option value=''>Select an endpoint</option>
                      {iconModel.icon_model_endpoints.map((endpoint) => {
                        return (
                          <option key={endpoint.id} value={endpoint.id}>
                            {endpoint.name}
                          </option>
                        )
                      })}
                    </select>
                  </div>
                </td>
                <td>
                  <div className={styles.iconScroller}>
                    <div className={styles.iconGrid}>
                      {results
                        .filter((result) => {
                          return !(
                            !selectedEndpoints[iconModel.id] ||
                            result.iconModelEndpointId !==
                              selectedEndpoints[iconModel.id]
                          )
                        })
                        .map((item) => {
                          return (
                            <Card
                              key={item.id}
                              id={item.id}
                              type={'icon'}
                              assetUrl={item.url}
                              iconVariants={[]}
                            />
                          )
                        })}
                    </div>
                  </div>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default Curation
