import { genericEvent } from '../../lib/events'

const logInteraction = ({ id, type, additionalData = {} }) => {
  try {
    fetch('/api/v1/image_gen/interact', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id: id,
        type: type,
        ...additionalData,
      }),
    })

    genericEvent({
      event: 'imageGenInteraction',
      data: {
        itemId: id,
        type: type,
        ...additionalData,
      },
    })
  } catch (e) {}
}

export default logInteraction
