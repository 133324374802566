import { useEffect, useRef, useState } from 'react'

export const usePromptRewrite = (prompt: string) => {
  const prev = useRef(prompt)
  const [rewrittenPrompt, setRewrittenPrompt] = useState<null | string>(null)
  const [rewriteLoading, setRewriteLoading] = useState(false)
  const rewriteTimeout = useRef(null)
  const fetchAbortController = useRef(null)

  useEffect(() => {
    return () => {
      // Cleanup function to abort any pending requests
      if (fetchAbortController.current) {
        fetchAbortController.current.abort()
      }
    }
  }, [])

  useEffect(() => {
    if (prev.current !== prompt) {
      console.log('Prompt changed:', [prev.current, prompt])
      prev.current = prompt
      // Abort any pending requests
      if (fetchAbortController.current) {
        fetchAbortController.current.abort()
      }

      clearTimeout(rewriteTimeout.current)
      rewriteTimeout.current = setTimeout(async () => {
        setRewriteLoading(true)
        setRewrittenPrompt(null)
        try {
          // Create new abort controller
          const newAbortController = new AbortController()

          fetchAbortController.current = newAbortController

          const response = await fetch('/api/v1/image_gen/prompt_rewrite', {
            method: 'POST',
            signal: fetchAbortController.current.signal,
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              prompt: prompt,
            }),
          })

          const data = await response.json()
          setRewrittenPrompt(data['rewrite_prompt'])
          setRewriteLoading(false)
        } catch (e) {}
      }, 500)
    }
  })

  return [rewriteLoading, rewrittenPrompt]
}
