import styles from './DownloadButton.module.scss'
import { useContext } from 'react'
import useImageGenStore from '../../lib/imageGenStore'
import { ModalContext } from '../../context/ModalContext'
import logInteraction from '../../lib/logInteraction'

export default function DownloadButton({ id, mode = null }) {
  const imageGens = useImageGenStore((state) => state.imageGens)

  return (
    <button
      onClick={() => {
        logInteraction({
          id: id,
          type: 'download',
          additionalData: imageGens[id].analytics,
        })
        const downloadUrl = imageGens[id].asset_url_download
        window.open(downloadUrl, '_blank')
      }}
      className={`${styles.button} ${mode === 'popup' ? styles.popup : ''}`}
    >
      <span className={styles.label}>Download</span>
      <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M12 15.7884L7.7308 11.5193L8.78462 10.4347L11.25 12.9V4.5H12.7499V12.9L15.2153 10.4347L16.2692 11.5193L12 15.7884ZM6.3077 19.5C5.80257 19.5 5.375 19.325 5.025 18.975C4.675 18.625 4.5 18.1974 4.5 17.6923V14.9808H5.99997V17.6923C5.99997 17.7692 6.03202 17.8397 6.09612 17.9038C6.16024 17.9679 6.23077 18 6.3077 18H17.6922C17.7692 18 17.8397 17.9679 17.9038 17.9038C17.9679 17.8397 18 17.7692 18 17.6923V14.9808H19.5V17.6923C19.5 18.1974 19.325 18.625 18.975 18.975C18.625 19.325 18.1974 19.5 17.6922 19.5H6.3077Z'
          fill={mode === 'popup' ? 'black' : 'white'}
        />
      </svg>
    </button>
  )
}
