import 'css-chunk:app/javascript/icon_gen/components/GeneratePrompt.module.scss';export default {
  "wrapper": "_wrapper_1u59i_1",
  "border": "_border_1u59i_15",
  "highlightNotice": "_highlightNotice_1u59i_37",
  "hasFocus": "_hasFocus_1u59i_40",
  "promptInput": "_promptInput_1u59i_61",
  "promptButton": "_promptButton_1u59i_115",
  "promptLabel": "_promptLabel_1u59i_140",
  "promptButtonLoading": "_promptButtonLoading_1u59i_149",
  "promptAlert": "_promptAlert_1u59i_153",
  "promptStyle": "_promptStyle_1u59i_179",
  "promptStyleThumb": "_promptStyleThumb_1u59i_184",
  "promptStyleClear": "_promptStyleClear_1u59i_190",
  "smartSuggestionWrapper": "_smartSuggestionWrapper_1u59i_199",
  "smartSuggestionIcon": "_smartSuggestionIcon_1u59i_211",
  "smartSuggestionTitle": "_smartSuggestionTitle_1u59i_215",
  "styleWrapper": "_styleWrapper_1u59i_227",
  "styleTitle": "_styleTitle_1u59i_232",
  "styleGrid": "_styleGrid_1u59i_236",
  "style": "_style_1u59i_227",
  "styleSelected": "_styleSelected_1u59i_250",
  "styleImages": "_styleImages_1u59i_254"
};