import 'css-chunk:app/javascript/image_gen/components/CardModalContent.module.scss';export default {
  "imageWrapper": "_imageWrapper_1jjeh_1",
  "imageContainer": "_imageContainer_1jjeh_20",
  "isLoading": "_isLoading_1jjeh_24",
  "actionsWrapper": "_actionsWrapper_1jjeh_28",
  "buttonWrapper": "_buttonWrapper_1jjeh_51",
  "image": "_image_1jjeh_1",
  "isSharing": "_isSharing_1jjeh_88",
  "licenseText": "_licenseText_1jjeh_93",
  "share": "_share_1jjeh_120",
  "shareField": "_shareField_1jjeh_143",
  "shareInput": "_shareInput_1jjeh_153",
  "shareCopyButton": "_shareCopyButton_1jjeh_167"
};