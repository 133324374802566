import styles from './Heading.module.scss'

type HeadingProps = {
  heading: string
  subheading: any
  showBeta: boolean
}

export default function Heading({
  heading,
  subheading,
  showBeta = true,
}: HeadingProps) {
  return (
    <div className={styles.wrapper}>
      <h1 className={styles.heading}>
        {heading}

        {showBeta && (
          <div className={styles.beta}>
            <span>Beta</span>
          </div>
        )}
      </h1>
      <p className={styles.subheading}>{subheading}</p>
    </div>
  )
}
