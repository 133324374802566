import styles from './CardModalContent.module.scss'
import { useContext, useEffect, useState } from 'react'
import LoadingAnimation from './LoadingAnimation'
import logInteraction from '../lib/logInteraction'
import useImageGenStore from '../lib/imageGenStore'
import { pageViewEvent } from '../../lib/events'
import UseStyleButton from './buttons/UseStyleButton'
import VoteButtons from './buttons/VoteButtons'
import { ImageGenContext } from '../context/ImageGenContext'
import DebugButton from './buttons/DebugButton'
import DownloadButton from './buttons/DownloadButton'
import ReportButton from './buttons/ReportButton'
import EditButtonBria from './buttons/EditButtonBria'
import ShareButton from './buttons/ShareButton'

type ImageGeneratorData = {
  id: string
  asset_url: string
  asset_url_thumb: string
  token: string
  caption: string
  debug: string
}

export default function CardModalContent({ imageGenId }) {
  const { isAdmin } = useContext(ImageGenContext)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const addImageGens = useImageGenStore((state) => state.addImageGens)
  const imageGens = useImageGenStore((state) => state.imageGens)
  const imageGen = imageGens[imageGenId]
  const vote = imageGen?.vote
  const assetUrl = imageGen?.asset_url

  // todo: extract sharing to separate component:
  const [sharing, setSharing] = useState(false)
  const [shareButtonCopyText, setShareButtonCopyText] = useState('Copy')

  useEffect(() => {
    if (!imageGen) return
    // Track that the user opened a modal:
    pageViewEvent({ pageType: 'imageGenModal' })

    logInteraction({
      id: imageGenId,
      type: 'modal_view',
      additionalData: imageGen.analytics,
    })
  }, [imageGen])

  useEffect(() => {
    // Figure out what data to display on the modal, first looking for a local cache of said data:
    if (imageGens[imageGenId] && assetUrl) {
      // First look for any cached data for this image so we can load it quickly.
      return
    }

    // If no cache is found, we do the network request to find that data:
    const fetchImageGen = async () => {
      try {
        const response = await fetch('/api/v1/image_gen/modal', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            id: imageGenId,
          }),
        })

        const data = (await response.json()) as ImageGeneratorData

        if (response.status !== 200 || !data.asset_url) {
          throw new Error('No image found')
        }
        addImageGens([data])
      } catch (e) {
        console.log(e)
        setLoading(false)
        setError(true)
      }
    }
    fetchImageGen()
  }, [imageGenId])

  return (
    <>
      <div
        className={`${styles.imageWrapper} ${loading ? styles.isLoading : ''} ${
          sharing ? styles.isSharing : ''
        }`}
        data-vote={vote}
        data-reported={imageGen?.reported}
      >
        {loading ? (
          <div className={styles.loadingWrapper}>
            <LoadingAnimation />
          </div>
        ) : null}
        {error ? <div>Error loading image</div> : null}
        {assetUrl ? (
          <div className={styles.imageContainer}>
            <img
              src={assetUrl}
              className={styles.image}
              onLoad={() => setLoading(false)}
              onError={() => setLoading(false)}
            />
            <div className={styles.licenseText}>
              <svg
                width='20'
                height='20'
                viewBox='0 0 20 20'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M9.37501 13.9585H10.625V9.16681H9.37501V13.9585ZM9.99999 7.74054C10.1907 7.74054 10.3506 7.67604 10.4796 7.54704C10.6086 7.41804 10.6731 7.25818 10.6731 7.06748C10.6731 6.87678 10.6086 6.71693 10.4796 6.58791C10.3506 6.45891 10.1907 6.39441 9.99999 6.39441C9.80928 6.39441 9.64943 6.45891 9.52043 6.58791C9.39143 6.71693 9.32693 6.87678 9.32693 7.06748C9.32693 7.25818 9.39143 7.41804 9.52043 7.54704C9.64943 7.67604 9.80928 7.74054 9.99999 7.74054ZM10.0014 17.9168C8.90644 17.9168 7.87725 17.709 6.9138 17.2935C5.95034 16.8779 5.11227 16.3139 4.39959 15.6016C3.6869 14.8892 3.12268 14.0515 2.70695 13.0885C2.29121 12.1255 2.08334 11.0965 2.08334 10.0015C2.08334 8.90659 2.29112 7.8774 2.70668 6.91396C3.12223 5.9505 3.68619 5.11243 4.39855 4.39975C5.11093 3.68705 5.94863 3.12284 6.91166 2.7071C7.87467 2.29137 8.90365 2.0835 9.99859 2.0835C11.0935 2.0835 12.1227 2.29127 13.0862 2.70683C14.0496 3.12239 14.8877 3.68634 15.6004 4.3987C16.3131 5.11108 16.8773 5.94878 17.293 6.91181C17.7088 7.87482 17.9166 8.9038 17.9166 9.99875C17.9166 11.0937 17.7089 12.1229 17.2933 13.0863C16.8777 14.0498 16.3138 14.8879 15.6014 15.6005C14.8891 16.3132 14.0514 16.8774 13.0883 17.2932C12.1253 17.7089 11.0963 17.9168 10.0014 17.9168ZM9.99999 16.6668C11.8611 16.6668 13.4375 16.021 14.7292 14.7293C16.0208 13.4376 16.6667 11.8613 16.6667 10.0001C16.6667 8.13903 16.0208 6.56264 14.7292 5.27098C13.4375 3.97931 11.8611 3.33348 9.99999 3.33348C8.13888 3.33348 6.56249 3.97931 5.27082 5.27098C3.97916 6.56264 3.33332 8.13903 3.33332 10.0001C3.33332 11.8613 3.97916 13.4376 5.27082 14.7293C6.56249 16.021 8.13888 16.6668 9.99999 16.6668Z'
                  fill='#666666'
                />
              </svg>
              <span>
                This image may be used for personal and commercial use, subject
                to the{' '}
                <a
                  href='https://labs.envato.com/image-gen/terms'
                  target='_blank'
                >
                  User Terms
                </a>
              </span>
            </div>
            <div className={styles.share}>
              <div className={styles.shareTitle}>
                Share the above image by copying this URL:
              </div>
              <div className={styles.shareField}>
                <div className={styles.shareIcon}>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    width='24'
                    height='24'
                  >
                    <path
                      fill='#231F20'
                      d='M14.201 10.019a4.227 4.227 0 0 0-1.71-.979l-.02-.007a3.857 3.857 0 0 0-4.03 1.18l-2.99 3.463c-1.453 1.701-1.269 4.367.438 5.962.818.763 1.876 1.176 2.97 1.132a3.944 3.944 0 0 0 1.62-.416 3.666 3.666 0 0 0 1.164-.923l2.82-3.273a4.853 4.853 0 0 1-1.764.033l-2.027 2.343a2.637 2.637 0 0 1-1.915.917 2.84 2.84 0 0 1-2.041-.776c-1.17-1.095-1.319-2.934-.296-4.102l3.004-3.47c.227-.274.504-.484.795-.632a2.63 2.63 0 0 1 1.12-.286 2.84 2.84 0 0 1 2.041.777 3.05 3.05 0 0 1 .94 1.756c.138-.088.276-.21.38-.334l.619-.717a4.356 4.356 0 0 0-1.118-1.648Zm1.222 4.617a4.05 4.05 0 0 1-1.62.416c-1.093.044-2.144-.355-2.97-1.132a4.53 4.53 0 0 1-.989-1.362l1.135-.521c.163.356.386.665.689.934a2.84 2.84 0 0 0 2.04.776 2.637 2.637 0 0 0 1.916-.917l2.795-3.225c.482-.56.725-1.298.664-2.075a3.076 3.076 0 0 0-.972-2.02c-1.17-1.094-2.954-1.034-3.964.127l-2.17 2.521-.97-.897 2.183-2.528c1.46-1.687 4.041-1.782 5.748-.186a4.495 4.495 0 0 1 1.406 2.942c.09 1.132-.251 2.2-.968 3.02l-2.789 3.204a3.961 3.961 0 0 1-1.164.923Z'
                    />
                  </svg>
                </div>
                <input
                  className={styles.shareInput}
                  defaultValue={`https://labs.envato.com/image-gen/${imageGenId}?utm_source=share&utm_campaign=share_modal`}
                />
                <button
                  type='button'
                  onClick={(e) => {
                    e.preventDefault()
                    const input = e.target.parentElement.querySelector('input')
                    input.select()
                    if (document.execCommand('copy')) {
                      setShareButtonCopyText('Copied')
                      setTimeout(() => {
                        window.getSelection().removeAllRanges()
                        setShareButtonCopyText('Copy')
                      }, 3000)
                    } else {
                      setShareButtonCopyText('Failed')
                      setTimeout(() => {
                        setShareButtonCopyText('Copy')
                      }, 3000)
                    }
                    return false
                  }}
                  className={styles.shareCopyButton}
                >
                  {shareButtonCopyText}
                </button>
              </div>
            </div>
          </div>
        ) : null}
      </div>
      <div className={styles.actionsWrapper}>
        <div className={styles.buttonWrapper}>
          <VoteButtons id={imageGenId} />
          <ReportButton id={imageGenId} />
          <ShareButton
            id={imageGenId}
            onClick={() => {
              setSharing((old) => !old)
            }}
          />
          {isAdmin && <DebugButton id={imageGenId} />}
        </div>
        <div className={styles.buttonWrapper}>
          <EditButtonBria id={imageGenId} />
          <UseStyleButton mode={'secondary'} id={imageGenId} />
          <DownloadButton id={imageGenId} />
        </div>
      </div>
    </>
  )
}
