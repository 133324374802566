import styles from './Modal.module.scss'
import { useCallback, useEffect, useRef } from 'react'
import { ModalContext } from '../context/ModalContext'

export default function Modal({ children, closeCallback }) {
  const modalRef = useRef()

  const detectEscapeKey = useCallback((e) => {
    if (e.key == 'Escape') {
      e.preventDefault()
      closeCallback()
      return false
    }
  }, [])

  useEffect(() => {
    document.body.addEventListener('keydown', detectEscapeKey)
    return () => {
      document.body.removeEventListener('keydown', detectEscapeKey)
    }
  }, [])

  return (
    <div
      className={`${styles.modalWrapper}`}
      ref={modalRef}
      onClick={(e) => {
        if (e.target === modalRef.current) {
          e.preventDefault()
          e.stopPropagation()
          closeCallback()
          return false
        }
      }}
    >
      <button
        title='Close Modal'
        className={styles.closeButton}
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          closeCallback()
          return false
        }}
      >
        <svg
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M6.40002 18.6538L5.34619 17.6L10.9462 12L5.34619 6.40002L6.40002 5.34619L12 10.9462L17.6 5.34619L18.6538 6.40002L13.0538 12L18.6538 17.6L17.6 18.6538L12 13.0538L6.40002 18.6538Z'
            fill='white'
          />
        </svg>
      </button>
      <ModalContext.Provider
        value={{
          closeCallback: closeCallback,
        }}
      >
        <div className={styles.modalContent}>{children}</div>
      </ModalContext.Provider>
    </div>
  )
  // {Children.map(children, child => {
  //           return cloneElement(child, { closeCallback: closeCallback })
  //         })}
}
