import { useCallback, useContext, useEffect, useState } from 'react'
import ImageGenRequest from './components/ImageGenRequest'
import { ImageGenContext } from './context/ImageGenContext'
import styles from './Generate.module.scss'
import useImageGenStore from './lib/imageGenStore'
import GeneratePrompt from './components/GeneratePrompt'
import { pageViewEvent } from '../lib/events'
import History from './components/History'

const Generate = () => {
  const imageGenRequests = useImageGenStore((state) => state.imageGenRequests)
  const pendingOperationComplete = useImageGenStore(
    (state) => state.pendingOperationComplete,
  )

  useEffect(() => {
    pageViewEvent({ pageType: 'imageGenGenerate' })
  }, [])

  useEffect(() => {
    window.scrollTo({
      behavior: 'smooth',
      top: 0,
    })
  }, [imageGenRequests])

  return (
    <main className={`${styles.wrapper} `}>
      <div className={styles.promptWrapper}>
        <GeneratePrompt />
      </div>
      {imageGenRequests.map((imageGenRequest) => {
        return (
          <ImageGenRequest
            key={imageGenRequest.id}
            {...imageGenRequest}
            completeCallback={() => {
              pendingOperationComplete(imageGenRequest.id)
            }}
          />
        )
      })}

      <History />
    </main>
  )
}

export default Generate
