import styles from './Card.module.scss'
import { useMemo, useState } from 'react'
import LoadingAnimation from './LoadingAnimation'

export default function Card({ id, type, assetUrl, iconVariants }) {
  const [isLoading, setIsLoading] = useState(true)
  const [variant, setVariant] = useState(null)

  return (
    <div
      className={`${styles.cardWrapper} ${styles.rounded} ${
        !isLoading ? styles.loaded : ''
      }`}
    >
      {isLoading && (
        <div className={styles.loadingWrapper}>
          <LoadingAnimation />
        </div>
      )}

      {assetUrl && (
        <>
          <img
            src={variant && variant >= 0 ? iconVariants[variant].url : assetUrl}
            className={styles.image}
            loading='lazy'
            onLoad={() => {
              setIsLoading(false)
            }}
            onError={() => {
              setIsLoading(false)
            }}
          />
          <div className={styles.variants}>
            {iconVariants.map((v, i) => (
              <div
                className={`${styles.variant} ${
                  variant === i ? styles.variantCurrent : ''
                }`}
                key={i}
                onMouseOver={() => {
                  setVariant(i)
                }}
              >
                <img src={v.url} />
                <span>{v.iconVariantName}</span>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  )
}
