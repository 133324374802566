import styles from './Card.module.scss'
import LoadingAnimation from './LoadingAnimation'

export default function CardPlaceholder() {
  return (
    <div className={`${styles.cardWrapper}`}>
      <div className={styles.loadingWrapper}>
        <LoadingAnimation />
      </div>
    </div>
  )
}
