import * as amplitude from '@amplitude/analytics-browser'
import { sendAnalyticsEvent } from '@envato/gtm-analytics'

export const pageViewEvent = ({ pageType }) => {
  const pageAttributes = {
    app_name: 'labs',
    app_version: '1',
    app_env: `${RAILS_ENV}`,
    page_type: pageType,
    event_attributes: null,
  }

  window.dataLayer.push(pageAttributes)
  window.dataLayer.push({
    event: 'analytics_ready',
    event_attributes: {
      custom_timestamp: Date.now(),
    },
  })

  // Legacy amplitude tracking directly:
  amplitude.track('pageView', {
    page: pageType,
  })
}

type AnalyticsEvent =
  | { event: 'imageGenFailed'; data: any }
  | { event: 'imageGenInteraction'; data: any }
  | { event: 'imageGenCreate'; data: any }
  | { event: 'briaEditorOpen'; data: any }
  | {
      event: 'briaEditorInteraction'
      data: {
        type:
          | 'Bria_ImageSave'
          | 'Bria_ImageSavePSD'
          | 'Bria_ImageDownload'
          | 'Bria_ApplyChange'
          | 'Bria_IframePageNavigation'
          | 'Bria_CloseClicked'
        messageType?: string
        messageActionKey?: string
        messageActionValue?: number
        analytics?: any
      }
    }

export const genericEvent = ({ event, data }: AnalyticsEvent) => {
  // Legacy amplitude tracking directly
  amplitude.track(event, {
    ...data,
  })

  // GTM dataLayer tracking
  sendAnalyticsEvent({
    eventName: event,
    ...data,
  })
}
