import styles from './Card.module.scss'
import { useContext, useState } from 'react'
import LoadingAnimation from './LoadingAnimation'
import { ImageGenContext } from '../context/ImageGenContext'
import { Link, useNavigate } from 'react-router-dom'
import useImageGenStore from '../lib/imageGenStore'
import UseStyleButton from './buttons/UseStyleButton'

type CardProps = {
  id: string
  assetUrl: string
  assetUrlThumb: string
  caption: string
  debug: any
}

export default function CardPublished({
  id,
  assetUrl,
  assetUrlThumb,
  caption,
  debug,
}: CardProps) {
  const [loaded, setLoaded] = useState(false)
  const { isAdmin, setModalContent, authorized, setModalReturnUrl } =
    useContext(ImageGenContext)
  const navigate = useNavigate()
  const imageGens = useImageGenStore((state) => state.imageGens)
  const imageGen = imageGens[id]
  const vote = imageGen?.vote

  return (
    <div
      className={`${styles.cardWrapper} ${loaded ? styles.loaded : ''}`}
      data-vote={vote}
      data-reported={imageGen?.reported}
    >
      <div className={styles.loadingWrapper}>
        <LoadingAnimation />
      </div>
      <div className={styles.voteStateWrapper}>
        {vote === 'upvote' && (
          <Link to={`/image-gen/${id}`} className={styles.upvotedIcon}>
            <svg
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M18 21H8V8L15 1L16.25 2.25C16.3667 2.36667 16.4625 2.525 16.5375 2.725C16.6125 2.925 16.65 3.11667 16.65 3.3V3.65L15.55 8H21C21.5333 8 22 8.2 22.4 8.6C22.8 9 23 9.46667 23 10V12C23 12.1167 22.9875 12.2417 22.9625 12.375C22.9375 12.5083 22.9 12.6333 22.85 12.75L19.85 19.8C19.7 20.1333 19.45 20.4167 19.1 20.65C18.75 20.8833 18.3833 21 18 21ZM6 8V21H2V8H6Z'
                fill='#191919'
              />
            </svg>
          </Link>
        )}
      </div>
      <div className={styles.useStyleWrapper}>
        <UseStyleButton id={id} />
      </div>
      <Link to={`/image-gen/${id}`} className={styles.imageWrapper}>
        <img
          src={assetUrlThumb}
          className={`${styles.image} ${loaded ? styles.loaded : ''}`}
          alt={caption}
          loading='lazy'
          onLoad={() => setLoaded(true)}
          onError={() => setLoaded(true)}
        />
      </Link>
    </div>
  )
}
