import { createContext } from 'react'

type IconGenContextType = {
  config?: {
    admin_icon_models: {
      id: number
      name: string
      image_variant: string
      caption_variant: string
      icon_model_endpoints: {
        id: number
        name: string
      }[]
    }[]
  }
}
export const IconGenContext = createContext<IconGenContextType>({})
