import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { IconGenContext } from './context/IconGenContext'
import { useState } from 'react'
import Home from './components/Home'
import Curation from './components/Curation'

export default ({ adminView, config }) => {
  return (
    <IconGenContext.Provider
      value={{
        config: config,
        isAdmin: adminView,
      }}
    >
      <Router>
        <Routes>
          <Route path='/icon-gen' element={<Home />} />
          <Route
            path='/admin/icon_pack_styles/:id/test_icon_gen'
            element={<Curation />}
          />
        </Routes>
      </Router>
    </IconGenContext.Provider>
  )
}
