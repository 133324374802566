import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Curation from './Curation'
import { ImageGenContext } from './context/ImageGenContext'
import { useState } from 'react'
import ImageGenWrapper from './ImageGenWrapper'

export default ({ adminView, config }) => {
  const [modalContent, setModalContent] = useState(null)
  const [debugModalContent, setDebugModalContent] = useState(null)
  const [imageGenOverallMode, setImageGenOverallMode] = useState(null)

  return (
    <ImageGenContext.Provider
      value={{
        authorized: true, // we use to have this react app public, now it's all authed only at rails level
        modalContent: modalContent,
        setModalContent: setModalContent,
        imageGenOverallMode: imageGenOverallMode,
        setImageGenOverallMode: setImageGenOverallMode,
        debugModalContent: debugModalContent,
        setDebugModalContent: setDebugModalContent,
        isAdmin: adminView,
      }}
    >
      <Router>
        <Routes>
          <Route
            path='/image-gen'
            element={<ImageGenWrapper config={config} mode={'home'} />}
          />
          <Route
            path='/image-gen/generate'
            element={<ImageGenWrapper config={config} mode={'generate'} />}
          />
          <Route
            path='/image-gen/favorites'
            element={<ImageGenWrapper config={config} mode={'favorites'} />}
          />
          <Route
            path='/image-gen/:imageGenId'
            element={<ImageGenWrapper config={config} mode={'single'} />}
          />
          <Route
            path='/admin/image_gen_styles/:id'
            element={<Curation config={config} />}
          />
        </Routes>
      </Router>
    </ImageGenContext.Provider>
  )
}
