import React from 'react'
import styles from './GeneratePrompt.module.scss'
import { useCallback, useState } from 'react'
import {
  GenerationParams,
  GenerationStyle,
} from '@envato/labs-extensions-client'

interface GeneratePromptProps {
  onGenerate: (generationParams: GenerationParams) => void
  loading: boolean
  generationStyles?: GenerationStyle[]
}

export default function GeneratePrompt({
  onGenerate,
  loading,
  generationStyles,
}: GeneratePromptProps) {
  const [focus, setFocus] = useState(false)
  const [prompt, setPrompt] = useState('')
  const [selectedStyle, setSelectedStyle] = useState<GenerationStyle | null>(
    null,
  )

  const generateIcons = useCallback(() => {
    if (!selectedStyle) {
      return
    }

    onGenerate({
      prompt,
      id: selectedStyle.id,
      icon_model_endpoint_id: selectedStyle.icon_model_endpoint_id,
      type: selectedStyle.type,
    })
  }, [onGenerate, selectedStyle, prompt])

  return (
    <div className={`${styles.wrapper} ${focus ? styles.hasFocus : ''}`}>
      <form
        action='/icon-gen'
        className={styles.border}
        onSubmit={(e) => {
          e.preventDefault()
          generateIcons()
          return false
        }}
      >
        <input
          type='search'
          className={styles.promptInput}
          value={prompt}
          data-prompt-focus='true'
          placeholder='Enter a subject (e.g. bonsai, cat)'
          onFocus={() => {
            setFocus(true)
          }}
          onBlur={() => {
            setFocus(false)
          }}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              event.preventDefault()
              generateIcons()
              return false
            }
          }}
          onChange={(e) => {
            setPrompt(e.target.value)
          }}
        />
        <button
          type='submit'
          className={`${styles.promptButton} ${
            loading ? styles.promptButtonLoading : ''
          }`}
          onClick={(e) => {
            e.preventDefault()
            generateIcons()
            return false
          }}
        >
          <span className={styles.promptLabel}>
            {loading ? 'Please wait' : 'Generate'}
          </span>
          <svg
            width='18'
            height='16'
            viewBox='0 0 18 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M8.74608 5.02464C9.08718 4.94904 9.35358 4.68264 9.42918 4.34154L10.2266 0.754138C10.4354 -0.185462 11.7746 -0.185462 11.9834 0.754138L12.7808 4.34154C12.8564 4.68264 13.1228 4.94904 13.4639 5.02464L17.0513 5.82204C17.9909 6.03084 17.9909 7.37004 17.0513 7.57884L13.4639 8.37624C13.1228 8.45184 12.8564 8.71824 12.7808 9.05934L11.9834 12.6467C11.7746 13.5863 10.4354 13.5863 10.2266 12.6467L9.42918 9.05934C9.35358 8.71824 9.08718 8.45184 8.74608 8.37624L5.15868 7.57884C4.21908 7.37004 4.21908 6.03084 5.15868 5.82204L8.74608 5.02464Z'
              fill='#E5E5E5'
            />
            <path
              d='M1.63803 12.6498C1.97913 12.5742 2.24553 12.3078 2.32113 11.9667L2.56503 10.8687C2.65233 10.4745 3.21393 10.4745 3.30123 10.8687L3.54513 11.9667C3.62073 12.3078 3.88713 12.5742 4.22823 12.6498L5.32623 12.8937C5.72043 12.981 5.72043 13.5426 5.32623 13.6299L4.22823 13.8738C3.88713 13.9494 3.62073 14.2158 3.54513 14.5569L3.30123 15.6549C3.21393 16.0491 2.65233 16.0491 2.56503 15.6549L2.32113 14.5569C2.24553 14.2158 1.97913 13.9494 1.63803 13.8738L0.540035 13.6299C0.145835 13.5426 0.145835 12.981 0.540035 12.8937L1.63803 12.6498Z'
              fill='#E5E5E5'
            />
          </svg>
        </button>
        <div className={styles.styleWrapper}>
          <div className={styles.styleTitle}>Choose an icon style:</div>
          <div className={styles.styleGrid}>
            {generationStyles
              ? generationStyles.map((style) => (
                  <div
                    className={`${styles.style} ${
                      style.id === selectedStyle?.id ? styles.styleSelected : ''
                    }`}
                    key={style.id}
                    onClick={() => setSelectedStyle(style)}
                    style={{
                      backgroundColor: style.background_color,
                    }}
                  >
                    <div className={styles.styleImages}>
                      {style.image_urls?.length > 0 &&
                        style.image_urls.slice(0, 2).map((image_url, index) => {
                          return (
                            <div key={index}>
                              <img src={image_url} alt={style.label} />
                            </div>
                          )
                        })}
                    </div>
                    <div className={styles.styleLabel}>{style.label}</div>
                  </div>
                ))
              : null}
          </div>
        </div>
      </form>
    </div>
  )
}
