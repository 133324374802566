import { useParams } from 'react-router'
import { ImageGenContext } from './context/ImageGenContext'
import { useCallback, useContext, useEffect } from 'react'
import CardModalContent from './components/CardModalContent'
import useImageGenStore from './lib/imageGenStore'
import { useNavigate } from 'react-router-dom'
import Home from './Home'
import Generate from './Generate'
import Modal from './components/Modal'
import Favorites from './Favorites'
import { Tooltip } from 'react-tooltip'
import styles from './ImageGenWrapper.module.scss'

type ImageGenWrapperProps = {
  config: {
    imageGenApiHostname: string
    imageGenApiPath: string
  }
  mode: 'home' | 'generate' | 'favorites' | 'single'
}

const ImageGenWrapper = ({ config, mode }: ImageGenWrapperProps) => {
  const { imageGenId } = useParams()
  const {
    setModalContent,
    imageGenOverallMode,
    setImageGenOverallMode,
    modalContent,
    debugModalContent,
    setDebugModalContent,
  } = useContext(ImageGenContext)
  const navigate = useNavigate()

  const currentSearchConfig = useImageGenStore(
    (state) => state.currentSearchConfig,
  )

  useEffect(() => {
    useImageGenStore.setState({ config: config })
  }, [])

  const closeModal = useCallback(() => {
    setModalContent(null)
    if (imageGenOverallMode === 'favorites') {
      navigate('/image-gen/favorites')
    } else if (imageGenOverallMode === 'generate') {
      navigate('/image-gen/generate')
    } else {
      navigate('/image-gen')
    }
  }, [imageGenOverallMode])

  useEffect(() => {
    if (mode === 'home') {
      window.scrollTo({
        behavior: 'smooth',
        top: 0,
      })
    }
  }, [currentSearchConfig])

  useEffect(() => {
    if (mode === 'generate' || mode === 'home' || mode === 'favorites') {
      setImageGenOverallMode(mode)
    }
    document.documentElement.dataset.pageType = mode
  }, [mode])

  useEffect(() => {
    if (imageGenId) {
      setModalContent(<CardModalContent imageGenId={imageGenId} />)
    } else {
      setModalContent(null)
    }
  }, [imageGenId])

  return (
    <>
      {modalContent && (
        <Modal
          closeCallback={() => {
            closeModal()
          }}
        >
          {modalContent}
        </Modal>
      )}
      {debugModalContent && (
        <Modal
          closeCallback={() => {
            setDebugModalContent(null)
          }}
        >
          {debugModalContent}
        </Modal>
      )}
      {imageGenOverallMode === 'home' && <Home />}
      {imageGenOverallMode === 'generate' && <Generate />}
      {imageGenOverallMode === 'favorites' && <Favorites />}
      <div className={styles.tooltipWrapper}>
        <Tooltip id='image-gen-tooltips' />
      </div>
    </>
  )
}

export default ImageGenWrapper
