import 'css-chunk:app/javascript/image_gen/components/GeneratePrompt.module.scss';export default {
  "wrapper": "_wrapper_bjirc_1",
  "border": "_border_bjirc_15",
  "highlightNotice": "_highlightNotice_bjirc_36",
  "hasFocus": "_hasFocus_bjirc_39",
  "promptInput": "_promptInput_bjirc_60",
  "promptButton": "_promptButton_bjirc_114",
  "promptLabel": "_promptLabel_bjirc_139",
  "promptButtonLoading": "_promptButtonLoading_bjirc_148",
  "promptAlert": "_promptAlert_bjirc_152",
  "promptStyle": "_promptStyle_bjirc_178",
  "promptStyleThumb": "_promptStyleThumb_bjirc_183",
  "promptStyleClear": "_promptStyleClear_bjirc_189",
  "smartSuggestionWrapper": "_smartSuggestionWrapper_bjirc_198",
  "smartSuggestionIcon": "_smartSuggestionIcon_bjirc_210",
  "smartSuggestionTitle": "_smartSuggestionTitle_bjirc_214",
  "smartSuggestionContent": "_smartSuggestionContent_bjirc_226"
};