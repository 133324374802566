import 'css-chunk:app/javascript/image_gen/components/Card.module.scss';export default {
  "cardWrapper": "_cardWrapper_1mror_1",
  "loaded": "_loaded_1mror_11",
  "rounded": "_rounded_1mror_14",
  "error": "_error_1mror_17",
  "imageWrapper": "_imageWrapper_1mror_24",
  "image": "_image_1mror_24",
  "fadeIn": "_fadeIn_1mror_1",
  "styleName": "_styleName_1mror_94",
  "useStyleWrapper": "_useStyleWrapper_1mror_104",
  "voteButtonsWrapper": "_voteButtonsWrapper_1mror_122",
  "voteStateWrapper": "_voteStateWrapper_1mror_141",
  "upvotedIcon": "_upvotedIcon_1mror_148",
  "loadingWrapper": "_loadingWrapper_1mror_172",
  "errorWrapper": "_errorWrapper_1mror_187",
  "errorIcon": "_errorIcon_1mror_195",
  "errorButton": "_errorButton_1mror_201"
};