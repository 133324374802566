import { Consent, deferRun } from './consent'
import { standardisedClickTracking } from '@envato/gtm-analytics'
import * as amplitude from '@amplitude/analytics-browser'

const hotJarInit = () => {
  // We do not want to show the hotjar popup during the SSO sign in step for canva.
  if (window.location.pathname.includes('/canva_extension')) {
    return
  }

  ;(function (h, o, t, j, a, r) {
    h.hj =
      h.hj ||
      function () {
        ;(h.hj.q = h.hj.q || []).push(arguments)
      }
    h._hjSettings = { hjid: 3757350, hjsv: 6 }
    a = o.getElementsByTagName('head')[0]
    r = o.createElement('script')
    r.async = 1
    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv
    a.appendChild(r)
  })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=')
}

const standardClicker = () => {
  window.addEventListener('click', standardisedClickTracking)
}

const legacyAmplitudeInit = () => {
  amplitude.init('c105c32cdc89318424c722980efac863', {
    defaultTracking: {
      pageViews: true,
      formInteractions: false,
      fileDownloads: false,
    },
  })

  const analytics = JSON.parse(
    document.getElementsByTagName('body')[0].dataset.analytics,
  )
  if (analytics) {
    if (analytics.user_id) {
      amplitude.setUserId(analytics.user_id)
    }
    const identifyEvent = new amplitude.Identify()
    identifyEvent.set('userType', analytics.user_type)
    amplitude.identify(identifyEvent)
  } else {
    const identifyEvent = new amplitude.Identify()
    identifyEvent.set('userType', 'unknown')
    amplitude.identify(identifyEvent)
  }
}

export const gtmInit = () => {
  ;(function (w, d, s, l, i) {
    w[l] = w[l] || []
    w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })
    const f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l != 'dataLayer' ? '&l=' + l : ''
    j.async = true
    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl
    f.parentNode.insertBefore(j, f)
  })(window, document, 'script', 'dataLayer', 'GTM-N3MNVB6D')
}

window.dataLayer = window.dataLayer || []

deferRun(hotJarInit, Consent.statistics)
deferRun(gtmInit, Consent.statistics)
deferRun(standardClicker, Consent.statistics)
deferRun(legacyAmplitudeInit, Consent.statistics)

window.addEventListener('load', () => {
  const cookieSettingsLink = document.querySelector('.js--cookie-settings-link')
  if (cookieSettingsLink) {
    cookieSettingsLink.addEventListener('click', (event) => {
      event.preventDefault()
      if (window.Cookiebot) {
        window.addEventListener('CookiebotOnLoad', () =>
          window.location.reload(),
        )
        window.Cookiebot.renew()
      }
      return false
    })
  }
})
